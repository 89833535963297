import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../resources/logo.png';
import LogoWhite from '../resources/logo_white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faListNumeric, faChartSimple, faUser, faList, faFileExport, faTicket, faGear, faPowerOff, faBars, faXmark, faMapMarker, faUsers } from '@fortawesome/free-solid-svg-icons';
import User from '../api/User.js';
import Cookies from 'js-cookie';
import ExportModal from './exportModal';
import { SessionContext } from '../SessionContext';
import Badge from '@mui/material/Badge';

function Menu({ active }) {
    const { isAuthenticated, session, sessionLoaded } = useContext(SessionContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(null);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [numberNotifications, setNumberNotifications] = useState(0);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const handleCloseExportModal = () => {
        setIsExportModalOpen(false);
    };

    useEffect(() => {
        (async () => {
            if (!sessionLoaded) {
                return; // Não faz nada até que a sessão seja carregada
            }
            const hasAdminRole = session.roles.some(role => role.name === "super");
            setIsAdmin(hasAdminRole);
            User.getSession()
                .then(async ({ data }) => {
                    console.log(data.notifications)
                    setNumberNotifications(data.notifications)
                }).catch(err => {
                    console.log('error on session:', err)

                    return;
                });
        })();
    }, [sessionLoaded]);

    const logout = () => {
        User.logout()
            .then(({ response }) => {
                console.log('logoutResponse', JSON.stringify(response));
                document.cookie = `XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
                Cookies.remove('XSRF-TOKEN');
                window.location.href = process.env.REACT_APP_FULL_DOMAIN + '/';
            }).catch(err => {
                if (err.response.status == 422) {

                    console.log('error on logout:', err.response.data.errors)

                    return;
                }
            });
    }

    return (
        <div>
            <div className={"menu-overlay d-sm-none " + (menuOpen ? 'open' : '')}></div>
            <div className={"menu-container " + (menuOpen ? 'open' : '')}>
                <div className="menu-logo-container">
                    <img className='menu-logo' src={Logo} alt='Conduril' />
                </div>
                <div className="menu-bell">
                    <Link to={'/notifications'}>
                        <Badge badgeContent={numberNotifications} color="error">
                            <FontAwesomeIcon icon={faBell} size="2xl" style={{color: "#ffd500",}}/>
                        </Badge>
                    </Link>
                </div>
                {isAdmin != null && session &&
                    <div className="menu-list">
                        <div className={"menu-item " + (active == 'dashboard' ? 'active' : '')}>
                            <Link to={'/dashboard'}>
                                <FontAwesomeIcon icon={faChartSimple} />
                                <span>Dashboard</span>
                            </Link>
                        </div>
                        <div className={"menu-item " + (active == 'mytrips' ? 'active' : '')}>
                            <Link to={'/mytrips'}>
                                <FontAwesomeIcon icon={faUser} />
                                <span>Minhas Viagens</span>
                            </Link>
                        </div>
                        <div className={"menu-item " + (active == 'trips' ? 'active' : '')}>
                            <Link to={'/trips'}>
                                <FontAwesomeIcon icon={faList} />
                                <span>Lista de Viagens</span>
                            </Link>
                        </div>
                        {isAdmin &&
                            <div className={"menu-item " + (active == 'all-trips' ? 'active' : '')}>
                                <Link to={'/all-trips'}>
                                    <FontAwesomeIcon icon={faListNumeric} />
                                    <span>Histórico Viagens</span>
                                </Link>
                            </div>
                        }
                        {isAdmin &&
                            <div className={"menu-item " + (active == 'destinies' ? 'active' : '')}>
                                <Link to={'/destinies'}>
                                    <FontAwesomeIcon icon={faMapMarker} />
                                    <span>Destinos</span>
                                </Link>
                            </div>
                        }
                        {isAdmin &&
                            <div className={"menu-item " + (active == 'export' ? 'active' : '')} onClick={() => setIsExportModalOpen(true)}>
                                <FontAwesomeIcon icon={faFileExport} />
                                <span>Exportação</span>
                            </div>
                        }
                        <div className={"menu-item " + (active == 'tickets' ? 'active' : '')}>
                            <Link to={'/tickets'}>
                                <FontAwesomeIcon icon={faTicket} />
                                <span>Tickets</span>
                            </Link>
                        </div>
                        {isAdmin &&
                            <div className={"menu-item " + (active == 'roles' ? 'active' : '')}>
                                <Link to={'/roles'}>
                                    <FontAwesomeIcon icon={faUsers} />
                                    <span>Roles</span>
                                </Link>
                            </div>
                        }
                        <div className={"menu-item " + (active == 'settings' ? 'active' : '')}>
                            <Link to={'/settings'}>
                                <FontAwesomeIcon icon={faGear} />
                                <span>Definições</span>
                            </Link>
                        </div>
                    </div>
                }
                <div className="btn-logout menu-item" onClick={logout}>
                    <FontAwesomeIcon icon={faPowerOff} />
                    <span>Logout</span>
                </div>
            </div>
            <div className="menu-top-mobile d-sm-none">
                <img className='menu-logo' src={LogoWhite} alt='Conduril' />
                <button className="btn-open-menu" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={menuOpen ? faXmark : faBars} />
                </button>
            </div>
            {isExportModalOpen && (
                <ExportModal
                    isOpen={isExportModalOpen}
                    onClose={handleCloseExportModal}
                />
            )}
        </div>
    );
}

export default Menu;
